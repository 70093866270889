//@ts-nocheck
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import baseInstance from "../api";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  requester_name: yup.string().required("Name is required"),
  requester_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  requester_phone_number: yup.string().required("Phone number is required"),
  message: yup.string().required("Message is required"),
});

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation("home");

  const onSubmit = async (data) => {
    console.log("Form submission initiated", data);
    data.request_type = "INFO";

    try {
      const response = await baseInstance({
        method: "POST",
        url: "/emails/landing-page/request",
        data,
      });
      const responseData = response.data;
      reset();
      toast.success(responseData.message);
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="h-full w-full py-24 flex justify-center items-center">
      <div className="w-full max-w-3xl bg-white p-8 rounded-2xl shadow-lg border border-[#black]">
        <div className="text-center mb-6">
          <h1 className="text-[20px] md:text-[30px] font-bold text-[black]">
            {t("For API and Pricing Inquiries, Reach out to Us")}
          </h1>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col md:flex-row gap-6">
            <input
              {...register("requester_name")}
              placeholder={t("Name")}
              className="w-full text-slate-600 bg-white border border-[#338FD9] rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-[#338FD9]"
            />
            <input
              {...register("requester_email")}
              placeholder={t("Email")}
              className="w-full text-slate-600 bg-white border border-[#338FD9] rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-[#338FD9]"
            />
          </div>

          <div className="flex flex-col gap-6">
            <Controller
              control={control}
              name="requester_phone_number"
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  defaultCountry="ke"
                  value={value}
                  onChange={onChange}
                  className="w-full text-slate-600 bg-white border border-[#338FD9] rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-[#338FD9]"
                />
              )}
            />
          </div>

          <textarea
            {...register("message")}
            placeholder={t("Your Request")}
            className="w-full text-slate-600 bg-white border border-[#338FD9] rounded-xl px-4 py-3 outline-none focus:ring-2 focus:ring-[#338FD9]"
            rows="5"
          />

          <button
            type="submit"
            className="w-full md:w-fit px-6 py-3 rounded-xl bg-[#338FD9] text-white font-bold hover:bg-[#006D04] transition-all duration-300"
          >
            {t("Submit")}
          </button>
        </form>
      </div>
    </div>
  );
}
