//@ts-nocheck
import CommonLayout from "../components/layout/CommonLayout";
import TeamSIngleCard from "../components/TeamSIngleCard";
import aboutUs from "../assets/images/thanksslide.webp";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const teamData = [
  {
    imgUrl: "German.jpg",
    teamName: "German Veggera",
    teamPost: "CEO & Founder",
  },
  {
    imgUrl: "Gustavo.jpg",
    teamName: "Gustavo Coello Flores",
    teamPost: "Agronomist",
  },
  { imgUrl: "German.jpg", teamName: "Madleine Mwithiga", teamPost: "Director" },
  {
    imgUrl: "Susan.jpg",
    teamName: "Susan Omenyo",
    teamPost: "Climate Smart Agronomist",
  },
  {
    imgUrl: "Sumbono.webp",
    teamName: "Sumbono",
    teamPost: "Backend Developer",
  },
  {
    imgUrl: "Sandeep.jpg",
    teamName: "Sandeep Thakur",
    teamPost: "Frontend Developer",
  },
  {
    imgUrl: "SamC.webp",
    teamName: "Sam Njogu",
    teamPost: "Technology Specialist",
  },
];

export const AboutUs = () => {
  const { t } = useTranslation("about");

  return (
    <>
      <CommonLayout isNavDark={true}>
        <div className="w-full py-20 bg-gradient-to-b from-[#0047AB] to-[#ffff]">
          <div className="container mx-auto text-center mb-12">
            {/* <h1 className="text-5xl md:text-6xl font-bold text-white">
              {t("About Us")}
            </h1> */}
          </div>

          <div className="container mx-auto flex flex-col md:flex-row items-center justify-center gap-10 p-4 md:p-0">
            <div className="w-full md:w-1/2">
              <img
                src={aboutUs}
                alt="About Us"
                loading="lazy"
                className="w-full rounded-2xl shadow-2xl transition-transform duration-500 hover:scale-90"
              />
            </div>

            <div className="w-full md:w-1/2 text-center md:text-left space-y-8">
              <h2 className="text-5xl font-bold text-white">
                {t("Our Story")}
              </h2>
              <p className="text-xl text-white leading-relaxed">
                {t(
                  "We are bankers, farmers, and scientists united to enhance the world’s climate resilience. Seeded by the Gates Foundation and recognized by Halcyon as a leading ClimateTech Startup, we seek to help everyone understand climate change and its impact in important day-to-day decisions. Headquartered in the United States, ADAPTA has operations in Africa and North America."
                )}
              </p>
            </div>
          </div>

          <div className="text-center py-12 mt-16">
            <button
              onClick={() => {
                Swal.fire({
                  title: t("Warning"),
                  html: t("You are about to be redirected to another website."),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#338FD9",
                  cancelButtonColor: "#d33",
                  confirmButtonText: t("OK"),
                  cancelButtonText: t("Close"),
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.open(
                      "https://adapta.earth/about-us",
                      "_blank",
                      "noreferrer"
                    );
                  }
                });
              }}
              className="bg-white text-[#0047AB] font-bold rounded-full text-xl px-10 py-4 shadow-lg hover:bg-[#0047AB] hover:text-[#ffff] transition-all duration-300"
            >
              {t("Meet Our Team")}
            </button>
          </div>
        </div>
      </CommonLayout>
    </>
  );
};
